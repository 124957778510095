/* Cross-browser support for ensuring functions fire when DOM is loaded */
if (document.readyState !== "loading") {
	initVideo();
	mailjetSignup();
} else {
	document.addEventListener("DOMContentLoaded", function () {
		initVideo();
		mailjetSignup();
	});
}

function initVideo() {
	const prefersReducedMotion = window.matchMedia(
		"(prefers-reduced-motion: reduce)"
	).matches;

	// If not mobile, or user doesn't prefer reduced motion, continue.
	if (!window.matchMedia("(min-width: 768px)").matches || prefersReducedMotion)
		return;

	const video = document.querySelector(".hero__video video");
	const videomp4 = document.querySelector(
		'.hero__video video source[type="video/mp4"]'
	);
	const videowebm = document.querySelector(
		'.hero__video video source[type="video/webm"]'
	);
	video.removeAttribute("hidden");
	videomp4.setAttribute("src", "assets/video/junxion-web.mp4");
	videowebm.setAttribute("src", "assets/video/junxion-web.webm");
}

async function mailjetSignup() {
	const form = document.querySelector("form.sign-up");
	const dialog = document.querySelector("dialog");
	const showButton = document.querySelector("#openDialog");
	const closeButton = document.querySelector("dialog button");

	if (!showButton.length) return;

	// "Show the dialog" button opens the dialog modally
	showButton.addEventListener("click", () => {
		dialog.showModal();
	});

	// "Close" button closes the dialog
	closeButton.addEventListener("click", () => {
		dialog.close();
	});

	await Promise.all([
		customElements.whenDefined("sl-button"),
		customElements.whenDefined("sl-checkbox"),
		customElements.whenDefined("sl-input"),
	]).then(() => {
		form.addEventListener("submit", (event) => {
			event.preventDefault();

			const formData = new FormData(form);

			fetch("/mailjet-signup", {
				method: "POST",
				body: formData,
			})
				.then((response) => response.json())
				.then((data) => {
					// Handle successful submission (show confirmation message)
					console.log("Subscribed:", data);
					notifyUser("success");
				})
				.catch((error) => {
					// Handle submission errors (display error message)
					console.error("Error:", error);
					notifyUser("error");
				});
		});
	});

	function notifyUser(success) {
		const submitBtn = form.querySelector("sl-button");
		submitBtn.style.display = "none";

		if (success == "success") {
			form
				.querySelector('sl-alert[variant="success"]')
				.setAttribute("open", true);
		} else {
			form
				.querySelector('sl-alert[variant="danger"]')
				.setAttribute("open", true);
		}
	}
}
